import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { AdsGETApi, AdsGETApiAuth, AdsPOSTApi, signInImage } from '../../../Constant/api';
import { slugs } from '../../../Constant/slugs';
import { logout } from '../../../redux/action';

export default function AllWeeklyExamList() {
  const [AllExamList1, setAllExamList1] = React.useState([]);
  const [reload, setReload] = React.useState(false)
  const [pagesLength, setPagesLength] = React.useState("1");
  const [pages, setPages] = React.useState(1);
  const dispatch = useDispatch()
  const LoginUser = useSelector((state) => state.LoginState);

  const fetchExam = (setAllExamList, perPage) => {
    setReload(true)
    AdsGETApiAuth({
      ...perPage,
    }, slugs.db_slug_paid_exam)
      .then((res) => {
        setAllExamList(res.data)
        setReload(false)
        console.log(res.data.length)
      })
      .catch(err => {
        console.log(err)
        console.log(err.response.data)
        if (err.message.includes("Request failed with status code 500"))
          dispatch(logout())
      })
  }
  const location = useLocation()
  useEffect(() => {
    fetchExam(setAllExamList1, {
      per_page: 1000, order: "asc",
      _fields: "id,title,slug,meta.exam_code,meta.available_unavailable,meta.exam_sub_type,meta.exam_code,meta.exam_question_block_student,meta.featured_image",
      "filter[meta_query][1][key]": "available_unavailable",
      "filter[meta_query][1][value][0]": "Available",
      "filter[meta_query][1][compare]": "=",
      "filter[meta_query][relation]": "AND",
      "filter[meta_query][2][key]": "exam_type",
      "filter[meta_query][2][value][0]": "UBT",
      "filter[meta_query][2][compare]": "=",
    })
    console.log(location.pathname.slice(11))
  }, [])

  return (
    <div>
      <div className="row">
        <div className="col-1"></div>
        <div className="col-10 form-view mt-3">
          <div className="row" style={{ margin: "10px" }}>
            <h2>All Exams</h2>
            {AllExamList1.map((item, index) => (
              <div key={index} className="col-lg-3 col-md-4 col-12 center" style={{ padding: "0 5px" }}>
                <ExamBlock item={item} examSlug={slugs.start_exam} />
              </div>
            ))}
            {AllExamList1.length == 0 &&
              <div style={{ padding: '10px' }}>
                No Exam Available Right now
              </div>
            }
          </div>
        </div>
      </div>

      {reload &&
        <div className="modal" style={{ padding: "50vw" }}>
          <div className="dot-pulse"></div>
        </div>
      }
    </div>
  )
}

const ExamBlock = ({ item, examSlug }) => {
  const location = useLocation();
  const [Code, setCode] = React.useState("")
  const [StartPressed, setStartPressed] = React.useState(false)
  return (
    <div style={{
      padding: "5px", margin: "5px 0px", border: "1.5px solid #bbb",
      borderRadius: "5px",
    }}>
      {item.meta.featured_image !== "" ?
        <img className="col-12" src={item.meta.featured_image}
          style={{ objectFit: 'contain', height: "100px", borderRadius: "3px" }} />
        :
        <img className="col-12"
          src={signInImage}
          style={{ objectFit: 'contain', height: "100px", borderRadius: "3px" }} />
      }
      <br />
      <div>
        {item.title.rendered}&nbsp;
      </div>

      <div style={{ width: "100%", display: 'flex', justifyContent: 'center' }}>
        {item.meta.exam_sub_type == "" ?
          <>
            {item.meta.exam_question_block_student == undefined ?
              <>
                {item.meta.exam_code == "" ?
                  <div onClick={() => { setStartPressed(false) }} className="sign-in-button-1 col-8 ">
                    <div style={{ color: "#fff" }}>
                      Pro
                    </div>
                  </div>
                  :
                  <div onClick={() => { setStartPressed(true) }} className="sign-in-button-1 col-8 ">
                    <div style={{ color: "#fff" }}>
                      Exam Code
                    </div>
                  </div>
                }
              </>
              :
              <>
                <div onClick={() => { setStartPressed(true) }} className="sign-in-button-1 col-8 ">
                  <div style={{ color: "#fff" }}>
                    Start Exam
                  </div>
                </div>
              </>
            }
          </>
          :
          <>
            <Link to={`${examSlug}/${item.slug}`}>
              <div onClick={() => { setStartPressed(false) }} className="sign-in-button-1 col-8 ">
                <div style={{ color: "#fff" }}>
                  Start Exam
                </div>
              </div>
            </Link>
          </>
        }

      </div >
      {
        StartPressed &&
        <div className="modal">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Exam Code</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                  onClick={() => { setStartPressed(false) }}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <p>Please Enter The Exam Code to start with your exam.</p>
                <input className="input-common"
                  value={Code} placeholder="Exam Code"
                  onChange={(event) => { setCode(event.target.value) }}
                />
              </div>
              <div class="modal-footer">
                {Code == item.meta.exam_code ?
                  <Link to={`${examSlug}/${item.slug}`}>
                    <button type="button" class="btn btn-primary"
                      onClick={() => {
                        localStorage.setItem("weekly_exam_code", Code)
                      }}
                    >Start Exam</button>
                  </Link>
                  :
                  <div type="button" class="btn btn-dark">Start Exam</div>
                }
                <button type="button" class="btn btn-secondary" data-dismiss="modal"
                  onClick={() => { setStartPressed(false) }}
                >Cancel</button>
              </div>
            </div>
          </div>
        </div>
      }
    </div >
  )

}